<template>
  <div class="home-wrapper">
    <div id="container" class="home-page flex  w-full bg-img vx-row no-gutter justify-center items-center  sm:py-0 md:py-0 lg:py-0 xl:py-0 py-20">
      <!--    &lt;!&ndash; Left Div &ndash;&gt;
          <div
            class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2  bg-beige rounded-br-3xl h-100"
          style="position: relative">
            <div class="text-content ml-6 mt-6 sm:px-0 md:px-4 lg:px-24 xl:px-7 px-2">
              <div class="vx-row w-full mt-6">
                <small class="text-primary text-base font-medium">
                  WRO Virtual
                </small>
              </div>
              <div class="vx-row w-full mt-4">
                <span class="text-grey text-5xl font-medium">
                   Robotics Competition
                  <span class="text-primary">A Global  </span>
                  <br/>
                  Competition
                </span>
              </div>
              <div class="vx-row w-full mt-0">
      &lt;!&ndash;          <small class="text-grey text-lg">&ndash;&gt;
      &lt;!&ndash;            A truly global competition <br/>&ndash;&gt;

      &lt;!&ndash;            dedicated to bringing participants together&ndash;&gt;
      &lt;!&ndash;          </small>&ndash;&gt;
              </div>
              <div class="vx-row w-full mt-6">
                <feather-icon
                  icon="PlayCircleIcon"
                  svgClasses="h-24 w-24 text-primary stroke-1"
                />
                <small class="text-grey font-bold text-md ml-2 mt-6">
                  <span class="text-primary">Watch</span> Demo
                  <br/>
                  <span class="text-primary">Video</span>
                </small>
              </div>
            </div>
          </div>

           Right Div
          <div
            class="first-img-section text-content w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 flex sm:justify-start md:justify-start lg:justify-start xl:justify-start justify-center  bg-white h-100"
          >
            <div class=" sm:px-0 md:px-4 lg:px-24 xl:px-64 px-2">
              <div class="vx-row w-full mt-6">
                <img
                  class="img-item  responsive w-78 h-72 rounded-b-3xl rounded-tl-3xl"
                  src="@/assets/images/Newfolder/1.jpeg"
                  alt="banner"
                />
              </div>
              <div class="vx-row w-full mt-6 ml-10">
                <img
                  class="img-item  responsive w-68 h-90 rounded-bl-3xl rounded-t-3xl"
                  src="@/assets/images/Newfolder/2.jpg"
                  alt="banner"
                />
              </div>
            </div>
          </div>

          <div id="circle">
            <div class="fx-width-2 vx-row w-full">
              <img
                class="img-item img-item-one responsive w-80 h-68 rounded-br-3xl rounded-t-3xl"
                src="@/assets/images/Newfolder/3.jpg"
                alt="banner"
              />
            </div>
      &lt;!&ndash;      <div&ndash;&gt;
      &lt;!&ndash;        @click="ScrollToSpotLinks()"&ndash;&gt;
      &lt;!&ndash;        class="vx-row flex justify-center w-full"&ndash;&gt;
      &lt;!&ndash;      >&ndash;&gt;
      &lt;!&ndash;        <svg&ndash;&gt;
      &lt;!&ndash;          xmlns="http://www.w3.org/2000/svg"&ndash;&gt;
      &lt;!&ndash;          width="24.047"&ndash;&gt;
      &lt;!&ndash;          height="38.047"&ndash;&gt;
      &lt;!&ndash;          viewBox="0 0 24.047 38.047"&ndash;&gt;
      &lt;!&ndash;        >&ndash;&gt;
      &lt;!&ndash;          <g&ndash;&gt;
      &lt;!&ndash;            id="Group_6222"&ndash;&gt;
      &lt;!&ndash;            data-name="Group 6222"&ndash;&gt;
      &lt;!&ndash;            transform="translate(-666 -665.476)"&ndash;&gt;
      &lt;!&ndash;          >&ndash;&gt;
      &lt;!&ndash;            <path&ndash;&gt;
      &lt;!&ndash;              id="Icon_ionic-md-arrow-down"&ndash;&gt;
      &lt;!&ndash;              data-name="Icon ionic-md-arrow-down"&ndash;&gt;
      &lt;!&ndash;              d="M19.5-8.023v32.26l8.417-8.417,2.1,2.18L18,30.023,5.977,18l2.1-2.1L16.5,24.237V-8.023Z"&ndash;&gt;
      &lt;!&ndash;              transform="translate(660.023 673.499)"&ndash;&gt;
      &lt;!&ndash;              fill="#252634"&ndash;&gt;
      &lt;!&ndash;            />&ndash;&gt;
      &lt;!&ndash;          </g>&ndash;&gt;
      &lt;!&ndash;        </svg>&ndash;&gt;
      &lt;!&ndash;      </div>&ndash;&gt;
      &lt;!&ndash;      <div&ndash;&gt;
      &lt;!&ndash;        @click="ScrollToSpotLinks()"&ndash;&gt;
      &lt;!&ndash;        class="vx-row w-full justify-center text-black text-sm font-bold mt-4 cursor-pointer"&ndash;&gt;
      &lt;!&ndash;      >&ndash;&gt;
      &lt;!&ndash;        Spot Links&ndash;&gt;
      &lt;!&ndash;      </div>&ndash;&gt;
          </div>
          <div
            id="SpotLinks"
            class="vx-row w-full text-black flex justify-center mt-10 text-2xl font-bold"
          >
            Spot Links
          </div>-->
      <FrontHome></FrontHome>

      <div class="fix-width-c ml-4 sm:px-0 md:px-4 lg:px-24 xl:px-64 px-2 mt-10">
        <div class="w-full p-2">
          <span class="text-primary text-4xl font-bold">
           {{ Settings.main_title }}
          </span>
        </div>
      </div>
      <div class="vx-row bg-white w-full">
        <div class="w-full p-2">
        </div>
      </div>
      <div class="vx-row bg-white mb-4 w-full">
        <div class="w-full p-2">
          <span class="text-black text-lg">
            {{ Settings.main_description }}
          </span>
        </div>
      </div>
      <div class="flex justify-center vx-row bg-white mt-8 w-full">
        <div class="flex justify-center w-full p-2">
          <vs-button
            class="mb-4 h-16 font-bold rounded-full md:w-auto float-left m-2"
            @click="$router.push({ name: 'MemberCountries' })"
            color="primary"
          >Countries
          </vs-button>
        </div>
      </div>
    </div>

    <div class="ml-4 w-full px-2 sm:px-0 md:px-4 lg:px-24 px-2 mt-10 mb-10">
      <div class="fix-width-c flex flex-wrap -mx-2">
        <div class="w-full xs:w-full bg sm:w-full px-2 mb-2">
          <div class="vx-row bg-white w-full">
            <div class="w-full p-2">
              <span class="text-primary text-4xl font-bold"> This is WRO-V </span>
            </div>
          </div>

          <div class="vx-row bg-white w-full mb-4">
            <div class="w-full p-2">
              <span class="text-black text-2xl font-bold">
                dedicated to bringing participants together
              </span>
            </div>
          </div>
        </div>

        <div class="vx-row bg-white w-full mt-2 home-slider">
          <div class="w-full ">
            <!-- swiper -->
            <swiper
              :options="swiperOption"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              :key="$vs.rtl">
              <swiper-slide
                v-for="(slideContent, index) in listBackup"
                :key="slideContent.id"
                :virtualIndex="slideContent.id">
                <div class="home-slide-row">
                  <div class="col-6" v-for="(img, innerIndex) in slideContent">
                    <img class="" :src="img.image" alt="banner"/>
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!-- DONE -->

    <!--     <div
          class="ml-4 w-full px-2 sm:px-0 md:px-4 lg:px-24 xl:px-64 px-2 mt-10 mb-10"
        >
          <ul class="vx-timeline">
            <li>
              <div class="vx-row flex-wrap mb-4 ">
                <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 ">
                  <img
                    class="responsive float-left  w-full"
                    src="@/assets/images/Layer.svg"
                    alt="banner"
                  />
                </div>
                <div
                  class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2  pt-10 mt-10"
                >
                  <span class="font-bold text-black text-2xl mt-6">
                    Big numbers
                  </span>
                  <p class="break-words align-baseline font-semibold mt-4">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer. Lorem Ipsum
                    is simply dummy text of the printing.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
    <div class="vx-row sm:px-0 md:px-4 lg:px-24 xl:px-7 px-2 mt-10  flex justify-center  bg-white w-full mt-10 mb-10">
      <div class="sm:w-1/3 md:w-1/3 lg:w-1/3  xl:w-1/3 w-full ">
        <h1 class="text-center align-bottom ml-4 mr-4 mt-6 ">
          Let's Work Together &
          <br/>
          Make a Better World
        </h1>
      </div>
      <div class="sm:w-1/3 md:w-1/3 lg:w-1/3  xl:w-1/3 w-full mt-10">
        <vs-divider
          class="float-right mb-0   invisible md:visible lg:visible xl:visible w-3/5 mt-6 "
          border-style="solid"
          style=""
          position="center"
          color="primary"
        ></vs-divider>
      </div>
      <div class="flex flex-wrap sm:w-1/3 md:w-1/3 lg:w-1/3  xl:w-1/3 w-full ">

        <vs-button
          class="text-center ml-auto mr-auto rounded-full break-normal h-36 w-36"
          type="border"
          @click="$router.push('/contactUs')">Contact Us
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import {swiper, swiperSlide} from "vue-awesome-swiper";
import FrontHome from "@/components/Home/FrontHome";
import {mapState} from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        slidesPerView: 1,
      },
      listBackup: [],
      images: []
    }
  },
  components: {
    FrontHome,
    swiper,
    swiperSlide
  },
  computed: {
    ...mapState('auth', {
      Settings: state => state.Settings
    })
  },
  mounted() {
    this.$API.home.homePhotos()
    .then(response => {
      this.images = response.data;
      let that = this;
      let count = 0;
      that.listBackup.push([]);
      this.images.map((el, index) => {
        that.listBackup[that.listBackup.length - 1].push(el);
        if ((index + 1) % 4 != 0) {
          count++;
        } else {
          if (index != that.images.length - 1) {
            count = 1;
            that.listBackup.push([])
          }
        }

      });

    })

  },
  methods: {
    ScrollToSpotLinks() {
      document.getElementById("SpotLinks").scrollIntoView();
    }
  }
};
</script>
<style>
.home-slider .swiper-slide {
  padding-right: 80px;
}

.home-slider .swiper-slide .response {
  width: 100%;
  object-fit: cover;
}


.margin-minus {
  margin-top: -47px !important;
}

.home-slide-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}


.home-slide-row .col-6 {
  width: calc((100% / 2) - 30px);
  margin: 0 0 20px;
}

.home-slide-row > div {
  overflow: hidden;
}

.home-slide-row .smaller-height {
  height: 220px;
}

.home-slide-row > div {
  height: 267px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-slide-row img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.home-wrapper .swiper-wrapper {
  height: 550px;
}

.fx-width-2 {
  width: 435px !important;
}

.fx-width-2 img {
  width: 100% !important;
}

.h-90 {
  height: 18rem !important;
}
</style>

<style lang="scss">

/*.carousel-example .swiper-container .swiper-slide {*/
/*  text-align: center;*/
/*  font-size: 38px;*/
/*  font-weight: 700;*/
/*  background-color: #eee;*/
/*  display: -webkit-box;*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  -webkit-box-pack: center;*/
/*  -ms-flex-pack: center;*/
/*  justify-content: center;*/
/*  -webkit-box-align: center;*/
/*  -ms-flex-align: center;*/
/*  align-items: center;*/
/*  min-height: 300px;*/
/*}*/


#container {
  position: relative;
}

// #Arrow {
//   position: absolute;
//   left: 0;
//   top: 0;
//   bottom: 7%;
//   right: 0;
//   height: 35px !important;
//   width: fit-content !important;
//   margin: auto;
//   padding-bottom: 20px;
// }
#circle {
  position: absolute;
  left: 0;
  top: 380px;
  right: 0;
  margin: auto;
  height: 285px !important;
  width: fit-content !important;
  margin: auto;
  margin-top: -40px;

  @media (max-width: 1200px) {
    top: 85vh !important;
  }
  @media (max-width: 471px) {
    top: 85vh !important;
    width: 100% !important;
  }
}


.home-page .text-content {
  @media (max-width: 1200px) {
    margin-top: 60px !important;
    padding-left: 7rem !important;
    padding-right: 0px !important;
  }
  @media (max-width: 933px) {
    padding-left: 2rem !important;
  }
}


.home-page .img-item {
  object-fit: cover;
  @media (max-width: 933px) {
    display: none;
  }
}


.home-page .first-img-section {
  @media (max-width: 933px) {
    display: none !important;
  }
}

.home-page .img-item-one {
  @media (max-width: 1200px) {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .swiper-wrapper {
    height: 400px;
  }

  .home-slide-row .smaller-height {
    height: 160px;
  }
  .home-slide-row > div {
    height: 207px;
  }

}

</style>
